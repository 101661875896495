<template>
    <div>
        <div class="frames-message">
            Screen size too small for creating frames.<br/>
            Please use a larger screen or increase your screen's resolution.
        </div>
        <div class="frames-wrapper" ref="frames">
            <template v-if="!renderOnly">
                <div class="frames-table">
                    <div class="frames-line headers">
                        <div v-for="key in ['name','color','fullscreen','left','top','width','height']"
                             class="cell cell-header" :class="key">
                            {{ key }}
                        </div>
                        <div class="cell edit"/>
                        <div class="cell delete"/>
                    </div>

                    <div class="frames-line" v-for="(f,i) in frames">
                        <div v-for="key in ['name','color','fullscreen','left','top','width','height']"
                             class="cell" :class="key"
                             :style="editIndex === i && key === 'color' ? {backgroundColor: f[key]} : ''">
                            <template v-if="editIndex !== i">
                                <div v-if="key==='color'" class="preview" :style="{backgroundColor: f[key]}"/>
                                <span v-if="key === 'fullscreen'">
                            {{ f[key] ? 'Yes' : 'No' }}
                        </span>
                                <span v-else-if="['name','color'].includes(key) || !frames[i].fullscreen">
                            {{ f[key] }}
                        </span>
                            </template>
                            <template v-else>
                                <input v-if="['left','top','width','height'].includes(key)"
                                       v-model.number="frames[i][key]" @input="updateFrame(key, $event)" type="number"
                                       step="1"
                                       min="0" :disabled="frames[i].fullscreen"/>

                                <input v-else-if="key==='fullscreen'"
                                       v-model="frames[i][key]" @change="updateFrame(key,$event)" type="checkbox"/>

                                <input v-else v-model="frames[i][key]" @input="updateFrame(key, $event)" type="text"/>
                            </template>
                        </div>

                        <div class="cell edit" @click="editFrame(i)" v-if="editIndex !== i">
                            <div class="cell-button">
                                <awesome-icon icon="edit"/>
                            </div>
                        </div>
                        <div class="cell edit" @click="saveFrame" v-else>
                            <div class="cell-button">
                                <awesome-icon icon="save"/>
                            </div>
                        </div>

                        <div class="cell delete" @click="removeFrame(i)">
                            <div class="cell-button">
                                <awesome-icon icon="trash"/>
                            </div>
                        </div>
                    </div>
                </div>

                <br/>

                <div style="display:flex">
                    <sc-button @click="addFrame">
                        <awesome-icon icon="plus" style="margin-right: .5em;"></awesome-icon>
                        Add Frame
                    </sc-button>
                </div>

                <br/>

                <div class="selected-info">
                    <div class="selected">
                        Selected frame:
                    </div>
                    <template v-if="frame && state === 'transform'">
                        <div class="selected-color" v-if="frame && state === 'transform'"
                             :style="{backgroundColor: frame.color}"/>
                        <div class="selected-name">
                            {{ frame.name }}
                        </div>
                    </template>
                    <div v-else style="margin-left:.5em;">No frame selected</div>
                </div>
            </template>

            <canvas class="frames-canvas" :width="canvasWidth" :height="canvasHeight" ref="canvas"
                    :style="{cursor: cursor}"
                    @mouseover="updateCursor"
                    @click="onCanvasClick" @mousedown="onCanvasDown" @mouseup="onCanvasUp" @mousemove="onCanvasMove"/>
        </div>
    </div>
</template>

<script>
import Frame from "../classes/Frame";
import Gizmo from "../classes/Gizmo";

export default {
  name: "frames-canvas",

  props: {
    width: {required: true, type: Number},
    height: {required: true, type: Number},
    formFrames: {required: false},
    renderOnly: {required: false, default: false, type: Boolean}
  },

  data() {
    return {
      // canvas
      maxWidth: 0,
      resizeTimeout: undefined,
      context: undefined,
      mouseDown: false,
      selectedIndex: undefined,
      cursor: 'default',
      multipliers: {
        width: 1,
        height: 1
      },

      // Movement
      frameStart: {x: undefined, y: undefined, w: undefined, h: undefined},
      mouseStart: undefined,
      transformLock: undefined,

      // frames
      frames: [],
      gizmos: [
        new Gizmo('top-left'),
        new Gizmo('top-center'),
        new Gizmo('top-right'),
        new Gizmo('top-left'),
        new Gizmo('top-left'),
        new Gizmo('middle-left'),
        new Gizmo('middle-right'),
        new Gizmo('bottom-left'),
        new Gizmo('bottom-center'),
        new Gizmo('bottom-right')
      ],
      editIndex: undefined,

      // renderer
      renderer: undefined
    }
  },

  computed: {
    ratio() {
      return this.width / this.height
    },

    canvasWidth() {
      return this.maxWidth;
    },

    canvasHeight() {
      return (this.canvasWidth / this.ratio)
    },

    state() {
      if (typeof this.selectedIndex !== 'undefined') {
        return 'transform'
      } else if (typeof this.editIndex !== 'undefined') {
        return 'edit'
      }
    },

    frame() {
      if (this.frames.length > 0) {
        if (this.state === 'transform') {
          return this.frames[this.selectedIndex]
        } else if (this.state === 'edit') {
          return this.frames[this.editIndex]
        }
      }
    }
  },

  mounted() {
    this.context = this.$refs.canvas.getContext('2d');
    this.setMaxWidth();

    // Add listener for resize events
    addEventListener('resize', this.setMaxWidth)

    // Check for existing frames
    this.renderExisting()
      .then(() => {
        // Get available width
        this.renderFrames();
      })
  },

  beforeUnmount() {
    // Cleanup
    clearTimeout(this.resizeTimeout);
    removeEventListener('resize', this.setMaxWidth)
  },

  watch: {
    width() {
      this.setMaxWidth()
    },
    height() {
      this.setMaxWidth()
    }
  },

  methods: {
    renderExisting() {
      return new Promise((resolve, reject) => {
        if (this.formFrames && Array.isArray(this.formFrames) && this.formFrames.length > 0) {
          for (let i = 0; i < this.formFrames.length; i++) {
            let f = this.formFrames[i];
            let newFrame = new Frame({name: f.name, properties: f.attributes ? f.attributes : f});
            this.frames.push(newFrame);

            if (i === this.formFrames.length - 1) {
              resolve();
            }
          }
        }
      })
    },

    /*
        START
        EVENT LISTENERS
    */
    setMaxWidth() {
      // debounce
      clearTimeout(this.resizeTimeout);
      this.clearCanvas();
      this.resizeTimeout = setTimeout(() => {
        // Reset to zero
        this.maxWidth = 0;

        // setTimeout(() => {
        let w = this.$refs.frames.offsetWidth;
        if (this.width < w) {
          w = this.width;
        }

        this.maxWidth = w;

        this.multipliers.width = this.width / this.canvasWidth;
        this.multipliers.height = this.height / this.canvasHeight;

        setTimeout(() => {
          this.renderFrames();
        }, 100)

      }, 250)
    },

    onCanvasClick(e) {
      if (!this.renderOnly) {
        if (this.state === 'edit') {
          this.saveFrame();
        }
        let offset = this.getOffsets(e);

        this.selectedIndex = this.getFrameHoverIndex({
          x: offset.x * this.multipliers.width,
          y: offset.y * this.multipliers.height
        });

        if (this.frame) {
          this.frameStart.x = this.frame.left;
          this.frameStart.y = this.frame.top;
          this.frameStart.w = this.frame.width;
          this.frameStart.h = this.frame.height;
        }

        this.renderFrames();
      }
    },

    onCanvasDown(e) {
      if (!this.renderOnly) {
        if (this.state === 'transform') {
          this.mouseDown = true;

          if (!this.mouseStart) {
            this.mouseStart = this.getOffsets(e);
          }

          if (!this.transformLock) {
            this.transformLock = this.updateCursor(e);
          }

          this.renderFrames()
        }
      }
    },

    onCanvasUp(e) {
      if (!this.renderOnly) {
        this.mouseDown = false;
        this.renderer = undefined;
        this.transformLock = undefined;
        this.frameStart = {x: undefined, y: undefined, w: undefined, h: undefined}
        this.mouseStart = undefined;
        window.cancelAnimationFrame(this.renderFrames)
      }
    },

    onCanvasMove(e) {
      if (!this.renderOnly) {
        if (!this.transformLock) {
          this.updateCursor(e);
        }

        if (this.mouseDown && this.frame) {
          // MoveDiff
          let offset = this.getOffsets(e)
          let diff = {
            x: Math.ceil((offset.x - this.mouseStart.x) * this.multipliers.width),
            y: Math.ceil((offset.y - this.mouseStart.y) * this.multipliers.height)
          }

          // Update positions object
          if (this.transformLock === 'move') {
            this.frame.left = this.frameStart.x + diff.x;
            this.frame.top = this.frameStart.y + diff.y;
            this.clamp();
          } else {
            this.handleResize(diff);
          }

          // Rerender
          this.renderer = window.requestAnimationFrame(this.renderFrames)
        }
      }
    },
    /*
        END
        EVENT LISTENERS
    */

    /*
        START
        MOUSE HANDLING
    */
    getFrameHoverIndex(offset) {
      this.selectedIndex = undefined;
      for (let i = this.frames.length; i > 0; i--) {
        if (this.frames[i - 1].mouseOver(offset) && !this.frames[i - 1].fullscreen) {
          return i - 1;
        }
      }
    },

    getOffsets(e) {
      let canvasRect = this.$refs.canvas.getBoundingClientRect()
      let offset = {
        x: e.clientX - canvasRect.left,
        y: e.clientY - canvasRect.top
      }
      return {x: offset.x, y: offset.y}
    },

    updateCursor(e) {
      if (this.cursor !== 'default') this.cursor = 'default';
      if (!this.renderOnly) {
        let offset = this.getOffsets(e);
        offset.x = offset.x * this.multipliers.width
        offset.y = offset.y * this.multipliers.height

        if (!this.frame) {
          if (typeof this.getFrameHoverIndex(offset) !== 'undefined') {
            this.cursor = 'pointer'
          }
        } else {
          if (this.frame.mouseOver(offset)) {
            this.cursor = 'move';
          }

          for (let i = 0; i < this.gizmos.length; i++) {
            if (this.gizmos[i].mouseOver(offset)) {
              this.cursor = this.gizmos[i].cursor;
            }
          }
        }
      }
      return this.cursor;
    },
    /*
        END
        MOUSE HANDLING
    */

    /*
        START SIZING
     */
    handleResize(diff) {

      let left, top, width, height = 0;
      // Cursor determines type of movement
      if (this.transformLock === 'nw-resize') {
        // TOP-LEFT
        left = this.frameStart.x + diff.x;
        top = this.frameStart.y + diff.y;
        width = this.frameStart.w - diff.x
        height = this.frameStart.h - diff.y;

        // Limit movement
        if (left < this.frameStart.x + this.frameStart.w) {
          this.frame.left = left;
          this.frame.width = width;
        }
        if (top < this.frameStart.y + this.frameStart.h) {
          this.frame.top = top;
          this.frame.height = height;
        }

      } else if (this.transformLock === 'n-resize') {
        // TOP-CENTER
        top = this.frameStart.y + diff.y;
        height = this.frameStart.h - diff.y;

        // Limit movement
        if (top < this.frameStart.y + this.frameStart.h) {
          this.frame.top = top;
          this.frame.height = height;
        }

      } else if (this.transformLock === 'ne-resize') {
        // TOP-RIGHT
        top = this.frameStart.y + diff.y;
        height = this.frameStart.h - diff.y;
        width = this.frameStart.w + diff.x;

        // Limit movement
        if (width > 0) {
          this.frame.width = width;
        }
        if (top < this.frameStart.y + this.frameStart.h) {
          this.frame.top = top;
          this.frame.height = height;
        }

      } else if (this.transformLock === 'w-resize') {
        // MIDDLE-LEFT
        left = this.frameStart.x + diff.x;
        width = this.frameStart.w - diff.x;

        // Limit movement
        if (left < this.frameStart.x + this.frameStart.w) {
          this.frame.left = left;
          this.frame.width = width;
        }

      } else if (this.transformLock === 'e-resize') {
        // MIDDLE-RIGHT
        width = this.frameStart.w + diff.x;

        // Limit movement
        if (width > 0) {
          this.frame.width = width;
        }

      } else if (this.transformLock === 'sw-resize') {
        // BOTTOM-LEFT
        height = this.frameStart.h + diff.y;
        left = this.frameStart.x + diff.x;
        width = this.frameStart.w - diff.x;

        // Limit movement
        if (height > 0) {
          this.frame.height = height;
        }
        if (left < this.frameStart.x + this.frameStart.w) {
          this.frame.left = left;
          this.frame.width = width;
        }

      } else if (this.transformLock === 's-resize') {
        // BOTTOM-CENTER
        height = this.frameStart.h + diff.y;

        // Limit movement
        if (height > 0) {
          this.frame.height = height;
        }

      } else if (this.transformLock === 'se-resize') {
        // BOTTOM-RIGHT
        height = this.frameStart.h + diff.y;
        width = this.frameStart.w + diff.x;

        // Limit movement
        if (height > 0) {
          this.frame.height = height;
        }
        if (width > 0) {
          this.frame.width = width;
        }
      }

      this.clamp();
      this.commitFrames();
    },

    clamp() {
      if (this.frame.left < 0) {
        this.frame.left = 0;
      }
      if (this.frame.top < 0) {
        this.frame.top = 0;
      }

      if (this.transformLock === 'move') {
        if (this.frame.left + this.frame.width > this.width) {
          this.frame.left = this.width - this.frame.width;
        }

        if (this.frame.top + this.frame.height > this.height) {
          this.frame.top = this.height - this.frame.height;
        }

      } else {
        if (this.frame.left + this.frame.width > this.width) {

          if (this.frame.left > 0) {
            this.frame.width = this.width - this.frame.left

          } else {
            this.frame.left = 0;
            this.frame.width = this.width;
          }
        }

        if (this.frame.top + this.frame.height > this.height) {
          if (this.frame.top > 0) {
            this.frame.height = this.height - this.frame.top;
          } else {
            this.frame.top = 0;
            this.frame.height = this.height;
          }
        }
      }
    },
    /*
        END SIZING
     */

    /*
        START RENDERING
    */

    renderFrames() {
      this.clearCanvas();

      for (let i = 0; i < this.frames.length; i++) {
        if (i === this.selectedIndex) continue;
        this.renderFrame(this.frames[i]);
      }

      if (typeof this.selectedIndex !== 'undefined' && typeof this.editIndex === 'undefined') {
        this.renderGizmos();
      }
    },

    renderFrame(frame) {
      if (!frame.fullscreen) {
        this.context.fillStyle = frame.color;

        // Take scale into account, divide all by ratio
        let x = frame.left / this.multipliers.width,
          y = frame.top / this.multipliers.height,
          w = frame.width / this.multipliers.width,
          h = frame.height / this.multipliers.height

        this.context.fillRect(x, y, w, h);
      }
    },

    renderGizmos() {
      this.renderFrame(this.frame);

      this.context.strokeWidth = 10;
      this.context.fillStyle = "rgba(255,255,255,.4)";

      this.gizmos.forEach((g) => {
        this.context.strokeStyle = g.color;

        // Position Gizmo
        let pos = g.positionGizmo(this.frame);

        let x = pos.x / this.multipliers.width;
        let y = pos.y / this.multipliers.height;
        let w = g.size / this.multipliers.width;
        let h = g.size / this.multipliers.height;
        this.context.fillRect(x, y, w, h)
        this.context.strokeRect(x, y, w, h);
      })

    },
    /*
        END RENDERING
    */

    /*
    START ACTIONS
    */
    commitFrames() {
      this.$emit('update:formFrames', this.frames);
    },

    addFrame() {
      // get frame index;
      let index = this.frames.length + 1;
      let frame = new Frame({name: `Frame-${index}`, properties: {zOrder: this.frames.length}});
      this.frames.push(frame);
      this.commitFrames();
      this.renderFrames();
    },

    removeFrame(i) {
      this.frames.splice(i, 1);
      if (this.frames.length > 0) {
        this.renderFrames();
      } else {
        this.editIndex = undefined;
        this.clearCanvas();
      }

      this.$emit('update:formFrames', this.frames);
    },

    clearCanvas() {
      this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
    },

    editFrame(i) {
      this.selectedIndex = undefined;
      this.editIndex = i;
      this.renderFrames();
    },

    updateFrame() {
      this.renderFrames()
      this.clamp();
      this.$emit('update:formFrames', this.frames);

    },

    saveFrame(e) {
      this.renderFrames()
      this.clamp();
      this.editIndex = undefined;
      this.$emit('update:formFrames', this.frames);
    }
  }
}
</script>

<style scoped lang="scss">

.frames-canvas {
    border: 1px solid #005172;
    background: #f3f3f3;
    box-sizing: border-box;
    user-select: none;
}

.selected-info {
    display: flex;
    margin-bottom: .5em;
    align-items: center;

    .selected {
        font-weight: bold;
    }

    .selected-color {
        width: 1em;
        height: 1em;
        display: block;
        margin: 0 .5em;
    }
}


.frames-table {

    font-size: .8em;

    .frames-line {
        display: grid;
        min-width: 340px;
        grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr .5fr .5fr;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        &.headers {
            background-color: $color-primary;
            color: #FFF;
            border-radius: 5px 5px 0 0;
        }


        .cell {
            min-width: 0;
            padding: .5em .75em;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            input {
                min-width: initial;
                max-width: 100%;
                padding: .5em .75em;
            }

            &.cell-header {
                text-transform: capitalize;
                font-weight: bold;
            }

            &.edit, &.delete {
                cursor: pointer;
            }

            &.color {
                .preview {
                    display: block;
                    width: 1em;
                    height: 1em;
                    margin-right: .5em;
                }
            }

            .cell-button {
                padding: .25em;
                background: $color-primary;
                color: #FFF;
                width: 35px;
                height: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &.delete .cell-button {
                background: $color-secondary;
            }
        }
    }
}

.frames-message {
    display: none;
}

@media only screen and (max-width: 1024px) {
    .frames-wrapper {
        display: none;
    }
    .frames-message {
        display: block;
    }
}

</style>