export default class Gizmo {
  constructor(name) {
    this.name = name;
    this.color = '#2d97cb';
    this.left = 0;
    this.top = 0;
    this.size = 20;
    this.cursor = 'default';

    this.setCursor(name);
  }

  setCursor(name) {
    let c = this.cursor;
    switch (name) {
      case 'top-left':
        c = 'nw-resize';
        break;
      case 'top-center':
        c = 'n-resize';
        break;
      case 'top-right':
        c = 'ne-resize';
        break;
      case 'middle-left':
        c = 'w-resize';
        break;
      case 'middle-right':
        c = 'e-resize';
        break;
      case 'bottom-left':
        c = 'sw-resize';
        break;
      case 'bottom-center':
        c = 's-resize';
        break;
      case 'bottom-right':
        c = 'se-resize';
        break;
    }
    this.cursor = c;
  }

  positionGizmo(frame) {
    let x = frame.left;
    let y = frame.top;

    // X offset
    if (this.name.includes('center')) {
      x += frame.width / 2;
    } else if (this.name.includes('right')) {
      x += frame.width;
    }

    // Y offset
    if (this.name.includes('middle')) {
      y += frame.height / 2;
    } else if (this.name.includes('bottom')) {
      y += frame.height;
    }

    this.left = x - this.size / 2;
    this.top = y - this.size / 2;
    return {x: this.left, y: this.top};
  }

  mouseOver({x, y}) {
    let inWidth = (x >= this.left) && (x <= (this.left + this.size));
    let inHeight = (y >= this.top) && (y <= (this.top + this.size));
    return inWidth && inHeight
  }
}