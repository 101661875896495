export default class Frame {
  constructor({name, properties}) {
    this.name = name;
    this.id = undefined;
    this.fullscreen = false;
    this.color = undefined;
    this.left = undefined;
    this.top = undefined;
    this.height = undefined;
    this.width = undefined;
    this.zOrder = undefined;
    this.initialValues(properties);
  }

  initialValues(properties) {
    ['id','fullscreen','color','left','top','width','height','zOrder'].forEach((key) => {
      if (properties && properties.hasOwnProperty(key)) {
        this[key] = properties[key];
      } else {
        if (key === 'color') {
          this.color = this.getRandomColor();
        } else if (!['id','fullscreen'].includes(key)) {
          this[key] = this.getRandomInt()
        }
      }
    })
  }

  getRandomInt() {
    return Math.floor(Math.random() * 100) + 100;
  }

  getRandomColor() {
    let c = '#'+Math.floor(Math.random() * 16777215).toString(16);
    let valid = c.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i);
    if (valid === null) {
      return this.getRandomColor();
    } else {
      return c
    }
  }

  mouseOver({x, y}) {
    let inWidth = (x >= this.left) && (x <= (this.left + this.width));
    let inHeight = (y >= this.top) && (y <= (this.top + this.height));
    return inWidth && inHeight
  }
}