<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="chevron-left" @click="$router.push(`/app/admin/${resource.route}`)">Back</sc-button>
        </div>
        <div class="pd">
            <sc-form :form="form" @submit="submit()" v-if="form">

                <template #field-dimension>
                    <sc-form-field name="Frameset dimension*">
                        <div style="display: flex;">
                            <sc-form-field :form="form" field="width" name=" ">
                                <input type="number" min="1" v-model="form.fields.width" placeholder="width"/>
                            </sc-form-field>

                            <sc-form-field :form="form" field="height" name=" ">
                                <input type="number" min="1" v-model="form.fields.height" placeholder="height"/>
                            </sc-form-field>
                        </div>
                    </sc-form-field>
                </template>

                <template #field-width>
                    <div class="test"/>
                </template>
                <template #field-height>
                    <div class="test"/>
                </template>

                <template #field-frames>
                    <sc-form-field name="Frames" :form="form" field="frames">
                        <frames-canvas :formFrames.sync="form.fields.frames" :width="form.fields.width"
                                       :height="form.fields.height"/>
                    </sc-form-field>
                </template>

            </sc-form>
        </div>
    </div>

</template>

<script>


import FramesCanvas from "@/views/app/admin/framesets/components/frames-canvas";


export default {
  name: "new",
  components: {FramesCanvas},
  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      form: null,
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.form = this.$sform.createForm({
      name: `New ${this.resource.name}`,
      url: this.resource.api,
      fields: {
        name: {
          name: 'Frameset Name*'
        },
        dimension: {},
        width: {
          default: 1920
        },
        height: {
          default: 1080
        },
        frames: {}
      },
      success: () => {
        this.$notify.success(`${this.resource.name} added`)
        this.$router.push(`/app/admin/${this.resource.route}`)
      }
    })
  },

  methods: {
    submit() {
      this.form.post();
    }
  }
}
</script>

<style scoped lang="scss">

.test {
    display: none;
}

</style>